<template>
  <div>
    <div v-if="!formVisible" class="view-wrap">
      <div class="search">
        <el-form :inline="true">
          <el-form-item class="hiddenFormItem">
            <el-input
              style="width: 100%"
              v-model="searcForm.param"
              placeholder="请输入编号"
            ></el-input>
          </el-form-item>

          <el-form-item label="业务日期">
            <el-row>
              <el-col :span="11">
                <el-date-picker
                  v-model="searcForm.date"
                  type="year"
                  format="yyyy"
                  value-format="yyyy"
                  :clearable="false"
                  @change="yearChange"
                >
                </el-date-picker>
              </el-col>
              <el-col :span="11">
                <el-select
                  v-model="searcForm.jidu"
                  style="width: 100%"
                  placeholder="请选择季度"
                >
                  <el-option
                    v-for="(item, Index) in jiduList"
                    :key="Index"
                    :label="item.name"
                    :value="item.id"
                  />
                </el-select>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="getData"
              ><i class="el-icon-search" /> 查询</el-button
            >
            <el-button type="default" plain @click="reset"> 清空</el-button>

            <!-- <el-button type="primary" plain @click="exportData">
              导出</el-button
            > -->
            <el-button type="danger" plain @click="deleteBatch">
              批量删除</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <div class="view">
        <SimpleTable
          :data="tableData"
          :tableFields="tableItem"
          :currentPage="searcForm.pageNum"
          :pageSize="searcForm.pageSize"
          :total="total"
          @sizeChange="changePageSize"
          @currentChange="changeCurrentPage"
          @selectChange="selectChange"
        >
          <div slot="operation" slot-scope="row">
            <el-button type="text" @click="WatchData(row.data)">查看</el-button>
            <el-button type="text" @click="EditData(row.data)">修改</el-button>
            <!-- <el-button type="text" @click="DeleteData(row.data)"
              >删除</el-button
            > -->
            <el-button type="text" @click="exportData(row.data.id)"
              >导出</el-button
            >
          </div>
        </SimpleTable>
      </div>
    </div>
    <emeLedgerForm ref="editForm" v-if="formVisible" @back="backList" />
  </div>
</template>
<script>
import {
  getEmeLedgerList,
  delEmeLedger,
  emeLedgerExport,
} from "@/api/emeLedger";
import emeLedgerForm from "./form.vue";
import { stringify } from "qs";
export default {
  components: { emeLedgerForm },
  data() {
    return {
      searcForm: {
        pageNum: 1,
        pageSize: 10,
        date: "",
        param: "",
        jidu: "",
      },
      UpdateType: null,
      tableData: [],
      total: 0,
      tableItem: [
        { label: "", type: "selection" },
        { label: "操作", type: "operation" },
        { prop: "name", label: "业务日期", type: "default" },
        { prop: "no", label: "台账编号", type: "default" },
        { prop: "saleTotal", label: "销售总量", type: "default" },
        { prop: "errorNumber", label: "事故数量", type: "number" },
      ],
      jiduList: [
        {
          name: "第一季度",
          id: "01",
        },
        {
          name: "第二季度",
          id: "04",
        },
        {
          name: "第三季度",
          id: "07",
        },
        {
          name: "第四季度",
          id: "10",
        },
      ],
      Visible: false,
      formVisible: false,
      checkList: [], // 列表已选项
    };
  },
  activated() {
    // this.GetCustomerType();
    this.getData();
  },
  methods: {
    WatchData(data) {
      this.formVisible = true;
      this.$nextTick(() => {
        this.$refs.editForm.init(data.id, true);
      });
    },
    add() {
      this.formVisible = true;
      this.$nextTick(() => {
        this.$refs.editForm.init(null, false);
      });
    },
    // 获取客户类别
    GetCustomerType() {
      GetCustomerTypeList().then((res) => {
        if (res.code === 1) {
          const { records } = res.data;
          this.typeList = records;
          // 默认选中
          this.getData();
        } else {
          this.typeList = [];
          this.$message.error("未获取到客户类别");
        }
      });
    },
    yearChange(val) {
      this.searcForm = { ...this.searcForm, jidu: "01" };
    },
    selectChange(val) {
      this.checkList = val;
    },
    backList() {
      this.formVisible = false;
    },
    getData() {
      const params = JSON.parse(JSON.stringify(this.searcForm));
      // if (params.checkPlace && params.checkPlace.length > 0) {
      //   params.checkPlace = params.checkPlace.join(";");
      // }
      // if (params.date) {
      //   params.startDate = params.date[0];
      //   params.endDate = params.date[1];
      // }
      if (params.date && params.jidu) {
        params.date = params.date + "-" + params.jidu;
      }
      getEmeLedgerList(params).then((res) => {
        if (res.code === 1) {
          const { records, current, size, total } = res.data;
          this.tableData = records;
          this.total = total;
          this.searcForm.pageNum = current;
          // 当没有数据
          if (records.length === 0) {
            // 判断是否为第一页
            if (current === 1) {
              this.searcForm.pageNum = 1;
              return;
            } else {
              // 不是回退再重新搜索
              this.searcForm.pageNum = current - 1;
              this.getData();
            }
          }
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 新增
    UpDate() {
      // this.$router.push({ path: "/data/customerForm" });
      this.AddVisible = true;
    },
    // 修改
    EditData(data) {
      this.formVisible = true;
      this.$nextTick(() => {
        this.$refs.editForm.init(data.id, false);
      });
      // this.$router.push({ path: "/data/EditCustomer", query: { id: data.id } });
    },
    refresh() {
      this.getData();
    },
    exportData() {
      // 导出
      emeLedgerExport(id).then((data) => {
        const fileName = data.fileName || "农药应急防护和应急处置记录.docx";
        //   res.data:请求到的二进制数据
        const blob = new Blob([data.data], {
          type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        }); //1.创建一个blob
        const link = document.createElement("a"); //2.创建一个a链接
        link.download = fileName; //3.设置名称
        link.style.display = "none"; // 4.默认不显示
        link.href = URL.createObjectURL(blob); // 5.设置a链接href
        document.body.appendChild(link); //6.将a链接dom插入当前html中
        link.click(); //7.点击事件
        URL.revokeObjectURL(link.href); //8.释放url对象
        document.body.removeChild(link); //9.移除a链接dom
      });
    },
    deleteBatch() {
      const ids = this.checkList.map((v) => {
        return v.id;
      });
      this.$confirm(`是否删除该记录？`, "提示", {
        confirmButtonText: "是",
        cancelButtonText: "否",
        type: "warning",
      }).then(() => {
        delEmeLedger(ids.join(",")).then((res) => {
          const { code, msg } = res;
          if (code === 1) {
            this.$message.success(msg);
          } else {
            this.$message.error(msg);
          }
          this.getData();
        });
      });
    },
    DeleteData(data) {
      this.$confirm(`是否删除该记录？`, "提示", {
        confirmButtonText: "是",
        cancelButtonText: "否",
        type: "warning",
      }).then(() => {
        delEmeLedger(data.id).then((res) => {
          const { code, msg } = res;
          if (code === 1) {
            this.$message.success(msg);
          } else {
            this.$message.error(msg);
          }
          this.getData();
        });
      });
    }, // 分页
    changePageSize(val) {
      this.searcForm.pageSize = val;
      this.searcForm.pageNum = 1;
      this.getData();
    },
    changeCurrentPage(val) {
      this.searcForm.pageNum = val;
      this.getData();
    },
    // 客户期初设置
    setting(data) {
      this.submitForm.customerId = data.id;
      this.submitForm.initialPayment = undefined;
      this.submitForm.initialAdvancePayment = undefined;
      this.Visible = true;
      this.$nextTick(() => {
        this.$refs.AddOrEditForm.init("客户期初设置");
      });
    },
    close() {
      this.$refs.AddOrEditForm.close();
    },
    reset() {
      this.searcForm = {
        date: "",
        param: "",
        jidu: "",
      };
    },
    exportData(id) {
      // 导出
      safeLedgerExport(id).then((data) => {
        const fileName = data.fileName || "农药应急防护和应急处置记录.docx";
        //   res.data:请求到的二进制数据
        const blob = new Blob([data.data], {
          type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        }); //1.创建一个blob
        const link = document.createElement("a"); //2.创建一个a链接
        link.download = fileName; //3.设置名称
        link.style.display = "none"; // 4.默认不显示
        link.href = URL.createObjectURL(blob); // 5.设置a链接href
        document.body.appendChild(link); //6.将a链接dom插入当前html中
        link.click(); //7.点击事件
        URL.revokeObjectURL(link.href); //8.释放url对象
        document.body.removeChild(link); //9.移除a链接dom
      });
    },
  },
};
</script>
